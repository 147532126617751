/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
// <!-- Global site tag (gtag.js) - Google Analytics -->

// <script async src="https://www.googletagmanager.com/gtag/js?id=UA-201489721-1"></script>

// <script>

// window.dataLayer = window.dataLayer || [];

// function gtag(){dataLayer.push(arguments);}

// gtag('js', new Date());

// gtag('config', 'UA-201489721-1');

// </script>

export const AnalyticsTags = () => {
  return (
    <>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-201489721-1" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
                    ${gaScript.toString()}
                    gaScript()
                    `
        }}
      />
    </>
  );
};

function gaScript() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    dataLayer.push(arguments);
  };
  gtag("js", new Date());

  gtag("config", "UA-201489721-1");
}

function gaEventListeners() {
  document.addEventListener("click", (e) => {
    const target = e.target.dataset.analytics;

    if (!target) return;
    const { action, category, label } = e.target.dataset;

    gtag("event", action, {
      event_category: category,
      event_label: label
    });
  });
}

export function fireEvent({ action, category, label, attributes = {} }) {
  gtag("event", action, {
    event_category: category,
    event_label: label,
    ...attributes
  });
}
